@import '@arco-themes/react-arco-pro/index.less';

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    background-color: var(--color-bg-1);
}

.chart-wrapper {
    .bizcharts-tooltip {
        background: linear-gradient(304.17deg,
                rgb(253 254 255 / 60%) -6.04%,
                rgb(244 247 252 / 60%) 85.2%) !important;
        border-radius: 6px;
        backdrop-filter: blur(10px);
        padding: 8px !important;
        width: 180px !important;
        opacity: 1 !important;
    }
}

body[arco-theme='dark'] {
    .chart-wrapper {
        .bizcharts-tooltip {
            background: linear-gradient(304.17deg,
                    rgba(90, 92, 95, 0.6) -6.04%,
                    rgba(87, 87, 87, 0.6) 85.2%) !important;
            backdrop-filter: blur(10px);
            border-radius: 6px;
            box-shadow: none !important;
        }
    }
}

.wac-form-modal {
    .arco-modal {
        width: auto;
        min-width: 400px;
        max-width: 640px;

        .arco-modal-content {
            min-height: 250px;
            max-height: 700px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

.arco-list-footer{
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* 修改全局滚动条样式 */
::-webkit-scrollbar {
    width: 0;
    /* 宽度 */
}

::-webkit-scrollbar-track {
    background: var(--color-neutral-1);
    /* 轨道背景色 */
}

::-webkit-scrollbar-thumb {
    background: var(--color-neutral-4);
    /* 滑块颜色 */
    border-radius: 10px;
    /* 滑块圆角 */
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-neutral-6);
    /* 鼠标悬停时滑块颜色 */
}

.number-style {
    font-size: 14px;
    font-family: 'NumberFont', sans-serif;
}

.skeleton-title1{
    &.arco-skeleton-text{
        .arco-skeleton-text-row{
            height: 30px;
        }
    }
}

.skeleton-title2{
    &.arco-skeleton-text{
        .arco-skeleton-text-row{
            height: 25px;
        }
    }
}

.skeleton-big-content{
    &.arco-skeleton-text{
        .arco-skeleton-text-row{
            height: 80px;
        }
    }
}

.arco-skeleton-text.staff-list-skeleton{
    .arco-skeleton-text-row:not(:last-child){
        margin-bottom: 8px;
    }
}

.approval-tabs{
    display: flex;
    flex-direction: column;
    height: 100%;
    .arco-tabs-content{
        flex: 1;
        .arco-tabs-content-inner{
            height: 100%;
        }
    }
}

.approval-anchor{
    .arco-anchor-link > .arco-anchor-link-title{
        font-size: 15px;
    }
    .arco-anchor-link > .arco-anchor-link-title:hover{
        font-size: 15px;
        background: none;
        color: rgb(var(--primary-6));
    }
    &.arco-anchor-horizontal .arco-anchor-link-active .arco-anchor-link-title:hover{
        font-size: 15px;
        background: none;
        color: rgb(var(--primary-6));
    }
    .arco-anchor-link-active > .arco-anchor-link-title{
        color: rgb(var(--primary-6));
    }
}

.approval-record-item .arco-timeline-item-dot-wrapper .arco-timeline-item-dot-content{
    width: 16px;
}
.approval-record-item .arco-timeline-item-content-wrapper{
    margin-left: 24px;
}
.approval-record-item .arco-timeline-item-dot-line{
    margin-top: 8px;
    margin-bottom: 8px;
}
.approval-record-card-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.arco-popover-content{
    padding: 16px !important;
}