.container {
    display: flex;
    height: 100vh;

    .content {
        flex: 1;
        position: relative;
        padding-bottom: 40px;
    }

    .footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.logo {
    position: fixed;
    top: 24px;
    left: 22px;
    display: inline-flex;
    align-items: center;
    z-index: 1;

    &-text {
        margin-left: 4px;
        margin-right: 4px;
        font-size: 20px;
        color: var(--color-text-1);
    }
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.forget-form {
    &-wrapper {
        width: 420px;
    }

    &-title {
        font-size: 24px;
        font-weight: 500;
        color: var(--color-text-1);
        line-height: 32px;
        margin-bottom: 35px;
    }

    &-item-title{
        font-size: 14px;
        margin-bottom: 5px;
        color: var(--color-text-2);
    }

    &-password-actions {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
    }

    &-iconSize{
        font-size: 18px !important;
    }

    &-submit{
        margin-top: 10px;
    }
}